.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 5s, opacity 5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
}
