@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-6xl;
}
h2 {
  @apply text-5xl;
}
h3 {
  @apply text-4xl;
}
h4 {
  @apply text-3xl;
}
h5 {
  @apply text-2xl;
}
h6 {
  @apply text-xl;
}

@layer utilities {
  @variants responsive {
    .vh-90 {
      height: 90vh;
    }
    .vh-85 {
      height: 85vh;
    }
    .vh-80 {
      height: 80vh;
    }
  }

  .bg-dark-gray {
    background-color: rgb(23, 23, 25);
  }

  .bg-soft-gray {
    background-color: #303030;
  }

  .bg-light-gray {
    background-color: #505050;
  }

  .text-gray-69 {
    color: #afafaf;
  }

  .success {
    color: #2cf536;
  }
}

.btn {
  @apply inline-block mx-1 px-4 border-2 border-gray-500 hover:border-white rounded;
}

.btn-active {
  @apply bg-soft-gray;
}
